import { CopyOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import styles from '@styles/Homepage/checklist/checklist_item.module.scss';
import Link from 'next/link';
import { useConfig } from 'providers';
import React from 'react';
import { useIntl } from 'react-intl';
import { Checklist } from 'services';
import { CommentIcon } from '../../CustomIcons';

type Props = {
  publicChecklistsData?: Checklist;
};

const ChecklistItem: React.FC<Props> = ({ publicChecklistsData }) => {
  const { formatMessage } = useIntl();
  const f = (id: string) => formatMessage({ id });
  const { theboatAppUrl } = useConfig();

  const intials = `${publicChecklistsData?.user?.name ? publicChecklistsData?.user?.name.charAt(0) : '-'}${
    publicChecklistsData?.user?.surname ? publicChecklistsData?.user?.surname.charAt(0) : '-'
  }`;

  return (
    <div className={styles.card}>
      <Link href={`checklists/${publicChecklistsData?.id}`} className={styles.card__image}>
        {publicChecklistsData?.image?.public_url && (
          <img src={publicChecklistsData?.image?.public_url} alt={'Public checklist image'} />
        )}
        {/* <div className={styles.card__share}>
          <ShareAltOutlined />
        </div> */}
      </Link>
      <div className={styles.card__wrapper}>
        <div className={styles.card__content}>
          <Link href={`checklists/${publicChecklistsData?.id}`} className={styles.content__title}>
            {publicChecklistsData?.title}
          </Link>
          <div
            className={styles.content__text}
            dangerouslySetInnerHTML={{
              __html:
                publicChecklistsData?.description.length > 100
                  ? publicChecklistsData?.description.substring(0, 100) + '...'
                  : publicChecklistsData?.description,
            }}
          />
        </div>

        <div>
          <div className={styles.card__user}>
            <div className={styles.user__initials}>{intials}</div>
            {publicChecklistsData?.user?.name} {publicChecklistsData?.user?.surname}
          </div>

          <div className={styles.card__footer}>
            <div className={styles.footer__left}>
              <UnorderedListOutlined /> {publicChecklistsData?.items?.length}
            </div>
            <ul className={styles.footer__right}>
              <li>
                <CommentIcon /> {publicChecklistsData?.comments_count}
              </li>
              <li>
                <UserOutlined /> {publicChecklistsData?.user_followers_count}
              </li>
              <li>
                <CopyOutlined /> {publicChecklistsData?.clones_count}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ul className={styles.cart__links}>
        <li>
          <Link href={`checklists/${publicChecklistsData?.id}`}>{f('homepage.checklist.item.view.text')}</Link>
        </li>
        <li>
          <Link href={`${theboatAppUrl}checklists/${publicChecklistsData?.id}`} target="_blank">
            {f('homepage.checklist.item.copy.text')}
          </Link>
        </li>
        <li>
          <Link href={`${theboatAppUrl}checklists/${publicChecklistsData?.id}`} target="_blank">
            {f('homepage.checklist.item.follow.text')}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ChecklistItem;
