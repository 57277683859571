import React from 'react';
import Link from 'next/link';
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from '@styles/Homepage/community.module.scss';
import { ComponentTbaLandingSectionsJoinUs } from 'services/graphql/cms';

interface Props {
  communityData: ComponentTbaLandingSectionsJoinUs;
}

const Community: React.FC<Props> = ({ communityData }) => (
  <section className={styles.container}>
    <div className={styles.container__content}>
      <h2 className={styles.content__title}>{communityData?.title}</h2>
      <div className={styles.content__text} dangerouslySetInnerHTML={{ __html: communityData?.description }} />

      <Link href={communityData?.joinUrl} className={styles.content__button} target="_blank" rel="noopener">
        Join us now <ArrowRightOutlined />
      </Link>
    </div>

    <div className={styles.container__image}>
      <img src="/images/homepage/person-with-flower-necklace.svg" alt="Person with flower necklace" />
    </div>
  </section>
);

export default Community;
