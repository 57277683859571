import { ArrowRightOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import styles from '@styles/Homepage/jumbotron.module.scss';
import { Button } from 'antd';
import Link from 'next/link';
import { useConfig } from 'providers';
import { FC } from 'react';
import { ComponentTbaLandingSectionsPageCover } from 'services/graphql/cms';

interface Props {
  pageCover: ComponentTbaLandingSectionsPageCover;
}

const Jumbotron: FC<Props> = ({ pageCover }) => {
  const { isAuthenticated } = useAuth0();
  const { theboatAppUrl } = useConfig();

  return (
    <section className={styles.container}>
      <div className={styles.containerContent}>
        <h1 className={styles.contentTitle}>{pageCover?.title}</h1>
        <div className={styles.contentText} dangerouslySetInnerHTML={{ __html: pageCover?.description }} />

        {isAuthenticated ? (
          <Button href={theboatAppUrl} className="ant-btn-special" size="large">
            Go to Dashboard <ArrowRightOutlined />
          </Button>
        ) : (
          <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-lg ant-btn-special">
            Get started <ArrowRightOutlined />
          </Link>
        )}
      </div>

      <div className={styles.containerImage}>
        <img src={pageCover?.image?.data?.attributes?.url} alt={pageCover?.image?.data?.attributes?.alternativeText} />
      </div>
    </section>
  );
};

export default Jumbotron;
