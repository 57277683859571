import { FC } from 'react';
import styles from '@styles/Homepage/available.module.scss';
import { ComponentTbaLandingSectionsAvailableOn } from 'services/graphql/cms';

interface Props {
  availableOn: ComponentTbaLandingSectionsAvailableOn;
}

const Available: FC<Props> = ({ availableOn }) => (
  <section className={styles.container}>
    <div className={styles.containerContent}>
      <div className={styles.containerImage}>
        <img
          className={styles.containerImageMain}
          src={availableOn?.coverImage?.data?.attributes?.url}
          alt={availableOn?.coverImage?.data?.attributes?.alternativeText}
        />

        <div className={styles.patterns}>
          <img src="/images/homepage/jumbotron-bottom-patter-1.png" alt="Pattern" />
          <img src="/images/homepage/jumbotron-bottom-patter-2.png" alt="Pattern" />
          <img src="/images/homepage/jumbotron-bottom-patter-3.png" alt="Pattern" />
        </div>
      </div>

      <div className={styles.bottomShape} />

      <h2 className={styles.contentTitle}>{availableOn?.title}</h2>
      <ul className={styles.contentItems}>
        {availableOn?.availableonOptions?.map((item) => (
          <li className={styles.contentItem} key={item.id}>
            <img
              src={item?.availableonImage?.data?.attributes?.url}
              alt={item?.availableonImage?.data?.attributes?.alternativeText}
              className={styles.itemIcon}
            />
            <p className={styles.itemText}>{item?.title}</p>
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default Available;
